import { ref } from 'vue'

export function useCarouselError() {
  const currentCarouselHasError = ref(false)

  function onCarouselError() {
    currentCarouselHasError.value = true
  }

  const resetCurrentCarouselError = () => {
    currentCarouselHasError.value = false
  }

  return {
    onCarouselError,
    showError: currentCarouselHasError,
    resetCurrentCarouselError,
  }
}

type CarouselIndex = number
type CarouselAttempts = number
/**
 * A successful Carousel should be cached with <KeepAlive /> to avoid re-fetching data and avoid re-executing the component's code (a Carousel component can take ~ 500ms to render).
 *
 * An "errored" Carousel (one for which the request failed) should not be cached. So that, when the user clicks on the thumbnail again, we re-fetch the data.
 */

export function useCarouselComponentCache() {
  const carouselsErrorCounts = new Map<CarouselIndex, CarouselAttempts>()

  function getCacheKey(carousel: { id: string; index: CarouselIndex }) {
    // each time, there is an error, we increase the error count with registerErroredCarousel
    // thanks to this, the next time we will have a different cache key so remount the component
    const errorCounts = carouselsErrorCounts.get(carousel.index) || 0

    return `${carousel.id}-${errorCounts}`
  }

  function registerErroredCarousel(carouselIndex: CarouselIndex) {
    const attempts = carouselsErrorCounts.get(carouselIndex) || 0
    carouselsErrorCounts.set(carouselIndex, attempts + 1)
  }

  const retries = ref(0)

  const incrementRetries = () => {
    retries.value += 1
  }

  return {
    registerErroredCarousel,
    getCacheKey,
    incrementRetries,
    retries,
  }
}
