export default {
  errorTitle: {
    id: 'enhanced_carousel_error_title',
    defaultMessage: "We can't find this",
  },
  errorMessage: {
    id: 'enhanced_carousel_error_message',
    defaultMessage: 'There might be an issue with your connection.',
  },
  tryAgain: {
    id: 'enhanced_carousel_try_again',
    defaultMessage: 'Try again',
  },
} as const
