<template>
  <div
    class="flex grow flex-col items-center justify-center"
    data-test="carousel-error"
  >
    <RevIllustration alt="" :height="98" src="/img/not-found.svg" :width="98" />

    <div class="text-static-default-mid heading-2 mt-32">
      {{ i18n(translations.errorTitle) }}
    </div>
    <div class="text-static-default-low body-2 mt-8">
      {{ i18n(translations.errorMessage) }}
    </div>

    <RevButton
      class="mt-32"
      data-test="retry-button"
      variant="secondary"
      @click="emits('retry-clicked')"
    >
      {{ i18n(translations.tryAgain) }}
    </RevButton>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './EnhancedCarousel.translations'

const i18n = useI18n()

const emits = defineEmits(['retry-clicked'])
</script>
